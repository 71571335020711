<app-top-nav-bar></app-top-nav-bar>

<div class="image-aboutus-banner">
  <div class="container">
    <div class="row">
        <div class="col-md-12">
         <h1 class="lg-text">Research & Development</h1>
         <p class="image-aboutus-para">Our engineering development group provides a complete service adapted to your needs.</p>
       </div>
    </div>
  </div>
</div>

<div class="aboutus-secktion paddingTB60">
  <div class="container">
      <div class="row">
                <div class="col-md-6"  data-aos="fade-up" data-aos-delay="100">
                    <h1 class="strong">Our Research and<br>Development</h1>
                  </div>
                  <div class="col-md-6"  data-aos="fade-up" data-aos-delay="100">
                    <p>Research and development is a valuable tool for growing and improving our business. The Digital Living R&D involves researching Sri Lanka market and our customer needs and developing new and improved products and services to fit these needs.</p>
                    <p>Our Business has strong R&D strategy to have a greater chance of success than businesses that don’t. We believe better R&D strategy can lead to innovation and increased productivity and can boost the business’s competitive advantage.</p>
                  </div>
      </div>
  </div>
</div>

<div class="text-center justify-content-center">
  <div class="site-heading text-center ">
  </div>
</div>

<!-- ======= Services Section ======= -->
<section id="services" class="services">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <i class="icofont-search-1"></i><h4>Analysis of needs and definition of product specifications.</h4>
        </div>
      </div>
      <div class="col-md-6">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <i class="icofont-settings-alt"></i>
          <h4>Product design and modeling.</h4>
        </div>
      </div>
      <div class="col-md-6">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <i class="icofont-sand-clock"></i>
          <h4>Verification tests and system validation in laboratory.</h4>
        </div>
      </div>
      <div class="col-md-6">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <i class="icofont-papers"></i>
          <h4>Development of functional production tests.</h4>
        </div>
      </div>
      <div class="col-md-6">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <i class="icofont-star"></i>
          <h4>Review of conception and design.</h4>
        </div>
      </div>
    </div>
  </div>
</section><!-- End Services Section -->

<section>
  <div class="heading">
    <h1>Our Researches</h1>
    <span></span> 
  </div>
  <div class="cd-half-width fourth-slide">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="content fourth-content">
            <div class="row">
              <div class="col-md-4 project-item img-fluid">
                <img src="assets/research/Research1.jpg">
              </div>
              <div class="col-md-4 project-item img-fluid">
               <img src="assets/research/Research2.jpg">
              </div>
              <div class="col-md-4 project-item img-fluid">
               <img src="assets/research/Research3.jpg">
              </div>
              <div class="col-md-4 project-item img-fluid">
                <img src="assets/research/planting.jpg">
              </div>
              <div class="col-md-4 project-item img-fluid">
               <img src="assets/research/planting2.jpg">
              </div>
              <div class="col-md-4 project-item img-fluid">
               <img src="assets/research/planting3.jpg">
              </div>
            </div>
          </div>
        </div>
      </div>                  
    </div>
  </div>
</section>