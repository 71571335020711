<!-- ======= Top Bar ======= -->
<section id="topbar" class="d-none d-lg-block">
  <div class="container clearfix">
    <div class="contact-info float-left">
      <!-- <a href="index.html"><img src="assets/image/logo-samples-final(2).png" alt="DL" class="img-fluid"></a> -->
      <!-- <img src="assets/image/logo-samples-final(2).png"> -->
      <i class="icofont-envelope"></i><a href="mailto:info@digitalliving.lk">info@digitalliving.lk</a>
      <i class="icofont-phone"></i><a href="tel:9-411-258-5125">+94 112 585 125</a>
    </div>
    <div class="social-links float-right">
      <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
      <a href="https://www.facebook.com/digitalliving.lk" class="facebook"><i class="icofont-facebook"></i></a>
      <a href="https://www.instagram.com/digitalliving43" class="instagram"><i class="icofont-instagram"></i></a>
      <a href="https://www.youtube.com/channel/UCafnMgEi9CgPatRqbSqz1dA" class="youtube"><i class="icofont-youtube-play"></i></a>
    </div>
  </div>
</section>

<!-- ======= Intro Section ======= -->
<section id="intro">
  <div class="intro-container">
    <div id="introCarousel" class="carousel  slide carousel-fade" data-ride="carousel">

      <ol class="carousel-indicators"></ol>

      <div class="carousel-inner" role="listbox">

        <div class="carousel-item active" style="background-image: url(/assets/image/test3.jpg)">
          <div class="carousel-container">
            <div class="container">
              <h2 class="animate__animated animate__fadeInDown">A Digitalized World<br>Within Your Tap</h2>
            </div>
          </div>
        </div>

        <div class="carousel-item" style="background-image: url(/assets/image/test4.jpg)">
          <div class="carousel-container">
            <div class="container">
              <h2 class="animate__animated animate__fadeInDown">A Friendly Automated Assistance<br>For Your Comfort</h2>
            </div>
          </div>
        </div>

        <div class="carousel-item" style="background-image: url(/assets/image/workshop.jpg)">
          <div class="carousel-container">3
            <div class="container">
              <h2 class="animate__animated animate__fadeInDown">We Are Changing The World<br>With Technology</h2>
            </div>
          </div>
        </div>

      </div>

      <a class="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon ion-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="#introCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon ion-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

    </div>
  </div>
</section><!-- End Intro Section -->

<!--Navbar -->
<nav class="mb-1 navbar navbar-expand-lg navbar-dark default-color sticky-top">
  <h4><a class="navbar-brand"  href="index.html"><span>Digital Living</span></a></h4>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-333"
    aria-controls="navbarSupportedContent-333" aria-expanded="false" aria-label="Toggle navigation" style="background-color : #040f23">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent-333">
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item active">
        <a class="nav-link waves-effect waves-light"  href="index.html">
          Home
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/solutions">
          Solutions
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/aboutUs">
          About Us
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/research">
          Research
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/Products">
          Products
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/contactUs">
          Contact Us
        </a>
      </li>
    </ul>
  </div>
</nav>
<!--/.Navbar -->

<!-- ======= Clients Section ======= -->
<div class="section-title" data-aos="fade-down" data-aos-duration="500">
  <h2>Principles</h2>
  <p></p>
</div>
<section id="clients" class="clients">
    <div class="container">
      <div class="row align-items-center">
        <div class="col col1">
          <img src="assets/image/brand/fibaro.png" class="img-fluid" alt="fibaro home intelligence" data-aos="zoom-in" style="width: 60%;">
        </div>
  
        <div class="col col2">
          <img src="assets/image/brand/danalock.png" class="img-fluid" alt="danalock" data-aos="zoom-in" data-aos-delay="100" style="width: 60%;">
        </div>
  
        <div class="col col3">
          <img src="assets/image/brand/gateman.png" class="img-fluid" alt="gateman" data-aos="zoom-in" data-aos-delay="100" style="width: 60%;">
        </div>
  
        <div class="col col4">
          <img src="assets/image/brand/mcohome.png" class="img-fluid" alt="mcohome" data-aos="zoom-in" data-aos-delay="100" style="width: 60%;">
        </div>
  
        <div class="col col5">
          <img class="img-fluid" src="assets/image/brand/sonos.png"  alt="sonos" data-aos="zoom-in" data-aos-delay="100" style="width: 60%;">
        </div>
      </div>
  
    </div>
  </section><!-- End Clients Section -->
   
    
      <!-- ======= Services Section ======= -->
      <section id="services" class="services">
        <div class="container">
          <div class="section-title" data-aos="fade-down" data-aos-duration="500">
            <h2>What we offer</h2>
            <p></p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <i class="fa icofont-home"></i>
                <h4><a routerLink = "/solutions">Home Intelligence</a></h4>
                <p>It brings integrated control of lights, HVAC, multimedia, alarms, blinds, gates, sensors and home appliances to your fingertips, from anywhere in the world</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <i class="icofont-building"></i>
                <h4><a routerLink = "/solutions">Smart Work Space</a></h4>
                <p>Transform your traditional workspace into the innovative smart space which takes safety and convenience to the next level with HVAC, lighting and surveillance on a single platform</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <i class="icofont-cc-camera" ></i>
                <h4><a routerLink = "/solutions">Smart Access Control & Surveillance</a></h4>
                <p>Strengthen building security and surveillance while allowing lightning-fast access for staff and approved visitors with the innovative Smart Access Control. You can now do it all from the comfort of your home/office or from anywhere on the go</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <i class="icofont-music-note"></i>
                <h4><a routerLink = "/solutions">Multi Room Audio</a></h4>
                <p>You no longer need to trail wires or install complex control systems to be able to send your favorite tunes all-around your home or one song in your bedroom. Everything controlled by the touch of a button or even just the sound of your voice</p>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="500">
                <i class="icofont-earth" style="color: #3145fa;"></i>
                <h4><a href="#">Magni Dolore</a></h4>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="600">
                <i class="icofont-tasks-alt" style="color: #a00098;"></i>
                <h4><a href="#">Eiusmod Tempor</a></h4>
                <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
              </div>
            </div> -->
          </div>
        </div>
      </section><!-- End Services Section -->

      <section class="pt-5 pb-5">
        <div class="container">
          <div class="section-title" data-aos="fade-down" data-aos-duration="500">
            <h2>Enrich Our Products</h2>
            <p></p>
          </div>
          <div class="row">
            
              
              <div class="col-12">
                  <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
      
                      <div class="carousel-inner">
                          <div class="carousel-item active">
                              <div class="row">
      
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/Gateways/Fibaro Home center 3/R_packshot_HC3_3_RGB1.jpg">
                                          <div class="card-body">
                                              <h4 class="card-title">Fibaro Home Center 3</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#homeCenter3">More</a></p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/light/FibaroDimmer2.png">
                                          <div class="card-body">
                                              <h4 class="card-title">Fibaro Dimmer 2</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#fibaroDimmer2">More</a></p>
      
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/safety/Fibaro Door Window Sensor/FGDW-002-1-Right.jpg">
                                          <div class="card-body">
                                              <h4 class="card-title">Fibaro Door Window Sensor</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#doorWindowSensor">More</a></p>
      
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="assets/products/Gatesnblinds/Fibaro Roller Shutter/Roller_Shutter_3_Right.jpg">
                                        <div class="card-body">
                                            <h4 class="card-title">Fibaro Roller Shutter</h4>
                                            <p class="card-text"><a href="#" data-toggle="modal" data-target="#rollerShutter">More</a></p>
    
                                        </div>
                                    </div>
                                </div>
      
                              </div>
                          </div>
                          <div class="carousel-item">
                              <div class="row">
      
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/Audio systems/Sonos one/sonos one.jpg">
                                          <div class="card-body">
                                              <h4 class="card-title">Sonos One</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#sonosOne">More</a></p>
      
                                          </div>
      
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/Digital Door Lock/Gateman G-Swipe/GatemanG-Swipe1.jpg">
                                          <div class="card-body">
                                              <h4 class="card-title">Gateman G-Swipe</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#gSwipe">More</a></p>
      
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/Gateways/Fibaro Home center lite/HCL_Right1.jpg">
                                          <div class="card-body">
                                              <h4 class="card-title">Fibaro Home Center Lite</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#homeCenterLite">More</a></p>
      
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="assets/products/light/FibaroRGBWController.png">
                                        <div class="card-body">
                                            <h4 class="card-title">Fibaro RGBW Controller</h4>
                                            <p class="card-text"><a href="#" data-toggle="modal" data-target="#fibaroRGBWController">More</a></p>
    
                                        </div>
                                    </div>
                                </div>
      
                              </div>
                          </div>
                          <div class="carousel-item">
                              <div class="row">

                                <div class="col-sm-3 col-md-3 d-flex">
                                  <div class="card">
                                      <img class="img-fluid" alt="100%x280" src="assets/products/Digital Door Lock/Gateman E300-FH/E300-FH.jpg">
                                      <div class="card-body">
                                          <h4 class="card-title">Gateman E300-FH</h4>
                                          <p class="card-text"><a href="#" data-toggle="modal" data-target="#gatemanE300FH">More</a></p>
  
                                      </div>
                                  </div>
                              </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/Gatesnblinds/Shutter panel Switch (2)/Shutter panel Switch white.png">
                                          <div class="card-body">
                                              <h4 class="card-title">Shutter Panel Switch</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#shutterPanelSwitch">More</a></p>
      
                                          </div>
      
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/biometric/surveillance/Hanvon Face station/Hanvon F910/Hanvon F910 2.jpg">
                                          <div class="card-body">
                                              <h4 class="card-title">Hanvon F910</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#hanvonF910">More</a></p>
      
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-3 col-md-3 d-flex">
                                      <div class="card">
                                          <img class="img-fluid" alt="100%x280" src="assets/products/light/TouchPanelSwitch(MH 314H).png">
                                          <div class="card-body">
                                              <h4 class="card-title">Touch Panel Switch MH 314H</h4>
                                              <p class="card-text"><a href="#" data-toggle="modal" data-target="#touchPanelMH314H">More</a></p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="carousel-item">
                            <div class="row">

                              <div class="col-sm-3 col-md-3 d-flex">
                                <div class="card">
                                    <img class="img-fluid" alt="100%x280" src="assets/products/safety/Fibaro Smoke Sensor/Smoke_Sensor_Right.jpg">
                                    <div class="card-body">
                                        <h4 class="card-title">Fibaro Smoke Sensor</h4>
                                        <p class="card-text"><a href="#" data-toggle="modal" data-target="#smokeSensor">More</a></p>

                                    </div>
                                </div>
                            </div>
                                <div class="col-sm-3 col-md-3 d-flex">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="assets/products/light/FibaroWallPlugPackshot.png">
                                        <div class="card-body">
                                            <h4 class="card-title">Fibaro Wall Plug-Type G</h4>
                                            <p class="card-text"><a href="#" data-toggle="modal" data-target="#fibaroWallPlug">More</a></p>
    
                                        </div>
    
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 d-flex">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="assets/products/safety/Fibaro Motion Sensor/Motion_Sensor_Right.jpg">
                                        <div class="card-body">
                                            <h4 class="card-title">Fibaro Motion Sensor</h4>
                                            <p class="card-text"><a href="#" data-toggle="modal" data-target="#motionSensor">More</a></p>
    
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3 d-flex">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src="assets/products/Digital Door Lock/Gateman J20/Gateman J20.jpg">
                                        <div class="card-body">
                                            <h4 class="card-title">Gateman J20</h4>
                                            <p class="card-text"><a href="#" data-toggle="modal" data-target="#J20">More</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      </div>
                  </div>
              </div>

          </div>

          <div style="text-align: center; padding-top: 20px;">
            <a class="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev" style="background-color: #040F23;">
              <i class="icofont-caret-left"></i>
            </a>
            <a class="btn btn-primary mb-3 " href="#carouselExampleIndicators2" role="button" data-slide="next" style="background-color: #040F23;">
                <i class="icofont-caret-right"></i>
            </a>
        </div>

      </div>
      </section>

      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container">
  
          <div class="section-title" data-aos="fade-down" data-aos-duration="500">
            <h2>EXPERIENCE CENTER</h2>
            <p></p>
          </div>
  
          <div class="row">
            <div class="col-xl-7 pt-4 pt-lg-0 d-flex align-items-stretch">
              <div class="content d-flex flex-column justify-content-center" data-aos="fade-left">
                <p>
                  Visit our Customer Experience Center to experience the digital and mixed reality solutions for the Smart home concept of the future
                </p>
                <div class="row">
                  <div class="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
                    <i class="icofont-puzzle"></i>
                    <h4>Inspire</h4>
                    <p>View a range of immersive demonstrations that showcase the digital and mixed reality solutions for the Home Intelligence, Smart Workspace, Smart Access Control & Surveillance and Multi-Room Audio concept</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
                    <i class="icofont-ui-calendar"></i>
                    <h4>Schedule your appointment </h4>
                    <p>We know that your time is valuable so we will schedule your appointment for an exact time on the day that you want it</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
                    <i class="icofont-unique-idea"></i>
                    <h4>Ideate</h4>
                    <p>Explore the latest trends and customize your plan to suit your budget, lifestyle or design preferences</p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="400">
                    <i class="icofont-location-pin"></i>
                    <h4>Check in for the demo</h4>
                    <p>Our experience center is located at Lauries Road, Colombo 04.</p>
                  </div>
                </div>
              </div><!-- End .content-->
            </div>
            <div data-aos="fade-right" class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start">
              <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">

                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleFade" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleFade" data-slide-to="1"></li>
                  <li data-target="#carouselExampleFade" data-slide-to="2"></li>
                  <li data-target="#carouselExampleFade" data-slide-to="3"></li>
                </ol>

                <div class="carousel-inner">
                  <div class="carousel-item active">
                      <img src="assets/image/Experience center/Experience Center1.jpg" class="d-block w-100 img-fluid" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="assets/image/Experience center/Experience Center2.jpg" class="d-block w-100 img-fluid" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="assets/image/Experience center/Experience Center3.jpg" class="d-block w-100 img-fluid" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="assets/image/Experience center/Experience Center4.jpg" class="d-block w-100 img-fluid" alt="...">
                  </div>
                </div>

              </div>
            </div>
          </div>
  
        </div>
      </section><!-- End About Section -->

        
  <!-- ======= Features Section ======= -->
  <section id="features" class="features">
    <div class="container">
      <div class="row">

        <!-- OUR PROJECTS -->
        <div class="col-6 d-flex align-items-stretch">
          <div class="card">
            <h5 class="card-title text-center justify-content-center">Our Projects</h5>
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img class="d-block w-100" style="height: 280px" src="assets/image/Projects/Elements Apartment.jpg" alt="First slide" data-toggle="modal" data-target="#modal1_elements">
                  <div class="card-body justify-content-center text-center">
                    <h6>House in Elements Apartment</h6>
                    <p class="card-text">Installed Digital door lock to main door of the apartment residence at Buthgamuwa Road. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <a><img class="d-block w-100" style="height: 280px;" src="assets/image/Projects/Kalapaluwawa.jpg" alt="Second slide" data-toggle="modal" data-target="#modal1_kalapaluwawa"></a>
                  <div class="card-body justify-content-center text-center">
                    <h6>Residance at Kalapaluwawa</h6>
                    <p class="card-text">Successfully completed the design and installed full scale Fibaro system for the two story house at Kalapaluwawa. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <a><img class="d-block w-100" style="height: 280px;" src="assets/image/Projects/Rajagiriya5.jpg" alt="Third slide" data-toggle="modal" data-target="#modal1_rajagiriya"></a>
                  <div class="card-body justify-content-center text-center">
                    <h6>Residance at Rajagiriya</h6>
                    <p class="card-text">Designed and installed a full scale Fibaro system to cover Lights, gates, safety and security requirement of the clients.</p>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div> 

         <!-- CLIENTS TESTIMONIALS -->
         <!-- <div class="col-lg-4 col-md-6 mt-5 mt-md-0 d-flex align-items-stretch">
          <div class="card" data-aos="fade-up" data-aos-delay="150" data-interval="100">
            <h5 class="card-title text-center justify-content-center">Clients Testimonials</h5>
            <div id="carouselExampleControls1" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img class="d-block w-100" style="height: 280px" src="assets/image/Hero-Bg2.jpg" alt="First slide" data-toggle="modal" data-target="#modal2">
                  <div class="card-body justify-content-center text-center">
                    <h6>Harry</h6>
                    <p class="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img style=" height: 280px" src="assets/image//Hero-Bg1.jpg" alt="Second slide" data-toggle="modal" data-target="#modal2">
                  <div class="card-body justify-content-center text-center">
                    <h6>John</h6>
                    <p class="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img style="height: 280px" src="assets/image/Hero-Bg3.jpg" alt="Third slide" data-toggle="modal" data-target="#modal2">
                  <div class="card-body justify-content-center text-center">
                    <h6>Jack</h6>
                    <p class="card-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>  -->

        <!-- EVENTS -->
        <div class="col-6 d-flex align-items-stretch">
          <div class="card">
            <h5 class="card-title text-center justify-content-center">Events</h5>
            <div id="carouselExampleControls2" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img class="d-block w-100" style="height: 280px" src="assets/image/Events/Architect Exhibition.JPG" alt="First slide" data-toggle="modal" data-target="#modal3_exhibition">
                  <div class="card-body justify-content-center text-center">
                    <h6>Architects 2020 Exhibition</h6>
                    <p class="card-text">Successfully participated for the architects’ 2020 exhibition at Bandaranaike Memorial International Conference Hall (BMICH). </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" style=" height: 280px" src="assets/image/Events/Installer Workshop.jpg" alt="Second slide" data-toggle="modal" data-target="#modal3_workshop">
                  <div class="card-body justify-content-center text-center">
                    <h6>Installer Workhop</h6>
                    <p class="card-text">Given trainings for the installers to how to professionally design and install the world’s most advanced IOT systems.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" style="height: 280px" src="assets/image/Events/Training Prgrm.JPG" alt="Third slide" data-toggle="modal" data-target="#modal3_training">
                  <div class="card-body justify-content-center text-center">
                    <h6>Training Program</h6>
                    <p class="card-text">Given trainings for the installers to how to professionally design and install the world’s most advanced IOT systems. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" style="height: 280px" src="assets/image/Events/Infotel 2017.JPG" alt="Fouth slide" data-toggle="modal" data-target="#modal3_infotel">
                  <div class="card-body justify-content-center text-center">
                    <h6>Infotel 2017 Exhibition</h6>
                    <p class="card-text">Given trainings for the installers to how to professionally design and install the world’s most advanced IOT systems. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" style="height: 280px" src="assets/image/Events/Reseller Work Shop1.jpg" alt="Fifth slide" data-toggle="modal" data-target="#modal3_resellerworkshop">
                  <div class="card-body justify-content-center text-center">
                    <h6>Reseller Workshop</h6>
                    <p class="card-text">A basic training was given for the Resellers about Our products, solutions and portfolio. </p>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div> 
        
      </div>
    </div>
  </section><!-- End Features Section -->



      <!--Modal: Projects at Elements-->
    <div class="modal fade" id="modal1_elements" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Our Projects</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic11" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Projects/Elements Apartment.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>House in Elements Apartment</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Elements Apartment2.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>House in Elements Apartment</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Elements Apartment3.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>House in Elements Apartment</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Elements Apartment4.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>House in Elements Apartment</h5></div>
                </div>
  
                <div class="carousel-item">
                  <img class="img-responsive" src="assets/image/Projects/Elements Apartment5.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>House in Elements Apartment</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic11" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic11" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Projects at Elements-->

    <!--Modal: Projects at kalapaluwawa-->
    <div class="modal fade" id="modal1_kalapaluwawa" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Our Projects</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic12" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Projects/Kalapaluwawa.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Kalapaluwawa</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Kalapaluwawa2.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Kalapaluwawa</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Kalapaluwawa3.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Kalapaluwawa</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Kalapaluwawa4.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Kalapaluwawa</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic12" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic12" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Projects at Kalapaluwawa-->

    <!--Modal: Projects at Rajagiriya-->
    <div class="modal fade" id="modal1_rajagiriya" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Our Projects</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic13" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Projects/Rajagiriya5.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Rajagiriya</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Rajagiriya2.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Rajagiriya</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Rajagiriya3.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Rajagiriya</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Projects/Rajagiriya.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Residance at Rajagiriya</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic13" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic13" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Projects at Rajagiriya-->
   
      <!--Modal: Clients Testimonials-->
      <div class="modal fade" id="modal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
        
          <!--Content-->
          <div class="modal-content">
            <div class="modal-header">
              <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Clients Testimonials</h5></div>
              <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
            </div>
            <div class="modal-body">
              <div id="carousel-example-generic2" class="carousel slide" data-ride="carousel">
              
                <div class="carousel-inner">
                  
                  <div class="carousel-item active">
                    <img class="img-responsive" src="assets/image/Hero-Bg1.jpg" style="width: 100%; height: auto;">
                    <div class="carousel-caption"> <h5>Image Description 1</h5></div>
                  </div>
    
                  <div class="carousel-item ">
                    <img class="img-responsive" src="assets/image/Hero-Bg2.jpg" style="width: 100%; height: auto;">
                    <div class="carousel-caption"> <h5>Image Description 2</h5></div>
                  </div>
    
                  <div class="carousel-item ">
                    <img class="img-responsive" src="assets/image/portfolio/E3.jpg" style="width: 100%; height: auto;">
                    <div class="carousel-caption"> <h5>Image Description 3</h5></div>
                  </div>
    
                  <div class="carousel-item ">
                    <img class="img-responsive" src="assets/image/portfolio/E1.jpg" style="width: 100%; height: auto;">
                    <div class="carousel-caption"> <h5>Image Description 4</h5></div>
                  </div>
    
                  <div class="carousel-item">
                    <img class="img-responsive" src="assets/image/portfolio/E2.jpg" style="width: 100%; height: auto;">
                    <div class="carousel-caption"> <h5>Image Description 5</h5></div>
                  </div>
    
                </div>
              </div>
  
              <a class="carousel-control-prev" href="#carousel-example-generic2" data-slide="prev">
                <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
      
              <a class="carousel-control-next" href="#carousel-example-generic2" role="button" data-slide="next">
                <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
  
            </div>
    
          </div>
          <!--/.Content-->
        </div>
      </div>
      <!--Modal: Clients Testimonials--> 

    <!--Modal: Events: Exhibition-->
    <div class="modal fade" id="modal3_exhibition" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Events</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic31" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Events/Architect Exhibition.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Architect Exhibition</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Architect Exhibition2.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Architect Exhibition</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Architect Exhibition3.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Architect Exhibition</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Architect Exhibition4.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Architect Exhibition</h5></div>
                </div>

              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic31" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic31" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Events: Exhibition-->

    <!--Modal: Events: Workshop-->
    <div class="modal fade" id="modal3_workshop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Events</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic32" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Events/Installer Workshop.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Installer Workshop</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Installer Workshop2.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Installer Workshop</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Installer Workshop3.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Installer Workshop</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Installer Workshop5.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Installer Workshop</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic32" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic32" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Events: Workshop-->

    <!--Modal: Events: Training-->
    <div class="modal fade" id="modal3_training" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Events</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic33" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Events/Training Prgrm.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Training Program</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Training Prgrm2.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Training Program</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Training Prgrm3.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Training Program</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Training Prgrm4.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Training Program</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic33" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic33" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Events: Training-->

    
    <!--Modal: Events: Reseller Workshop-->
    <div class="modal fade" id="modal3_resellerworkshop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Events</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic34" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Events/Reseller Work Shop1.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Reseller Workshop</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Reseller Work Shop2.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Reseller Workshop</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Reseller Work Shop3.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Reseller Workshop</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Reseller Work Shop4.jpg" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Reseller Workshop</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic34" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic34" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Events: Reseller Workshop-->

    
    <!--Modal: Events: Infotel 2017-->
    <div class="modal fade" id="modal3_infotel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
      
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <div class="justify-content-center text-center"><h5 class="card-title text-center justify-content-center">Events</h5></div>
            <button type="button" class="close" data-dismiss="modal" title="Close"> <span><i class="icofont-close-circled"></i></span></button>
          </div>
          <div class="modal-body">
            <div id="carousel-example-generic35" class="carousel slide" data-ride="carousel">
            
              <div class="carousel-inner">
                
                <div class="carousel-item active">
                  <img class="img-responsive" src="assets/image/Events/Infotel 2017.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Infotel 2017</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Infotel 2017 2.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Infotel 2017</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Infotel 2017 3.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Infotel 2017</h5></div>
                </div>
  
                <div class="carousel-item ">
                  <img class="img-responsive" src="assets/image/Events/Infotel 2017 4.JPG" style="width: 100%; height: auto;">
                  <div class="carousel-caption"> <h5>Infotel 2017</h5></div>
                </div>
  
              </div>
            </div>

            <a class="carousel-control-prev" href="#carousel-example-generic35" data-slide="prev">
              <span class="carousel-control-prev-icon icofont-thin-double-left" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
    
            <a class="carousel-control-next" href="#carousel-example-generic35" role="button" data-slide="next">
              <span class="carousel-control-next-icon icofont-thin-double-right" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>

          </div>
  
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Events: infotel-->


    <!-- Modal Fibaro Dimmer 2 -->
<div class="modal product-modal fade" id="fibaroDimmer2" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/light/FibaroDimmer2.png" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Dimmer 2</h2>
                          <p class="product-short-description">
                              Fibaro Dimmer 2 is a revolution in lighting control. Thanks to its unique and innovative features the 
                              Dimmer 2 can recognize the connected light source and adapt itself to it. Z-wave controlled light dimming 
                              module, designed to work with various types of light sources. Fibaro Dimmer 2 can switch or dim connected 
                              light source either through Z-waves or through the wall switch connected directly to it. The power metering 
                              functionality helps to optimize your electricity bills. Using the scene activation you can customize your 
                              home even further.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro RGBW Controller -->
<div class="modal product-modal fade" id="fibaroRGBWController" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/light/FibaroRGBWController.png" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro RGBW Controller</h2>
                          <p class="product-short-description">
                              Fibaro RGBW Controller is a one of a kind, advanced wireless 4-color LED strip controller. Apart from traditional RGB channels, it also supports the additional white light channel, which allows for adding pastel to the color scheme. In addition the device supports up to four, 0V - 10V analogue sensors, such as temperature sensors, humidity sensors, wind sensors, air quality sensors, light sensors etc. 
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Wall Plug-Type G -->
<div class="modal product-modal fade" id="fibaroWallPlug" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/light/FibaroWallPlugPackshot.png" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Wall Plug-Type G</h2>
                          <p class="product-short-description">
                              FIBARO Wall Plug is a remotely controlled plug-in switch with the ability to measure power and energy consumption. The crystal LED ring changes its color according to the amount of power used by the plugged device. Wall Plug comes equipped with a power measurement feature. It helps you effortlessly identify the most energy-hungry pieces of hardware or monitor energy usage in particular rooms or during particular periods.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Touch Panel Switch MH 314H  -->
<div class="modal product-modal fade" id="touchPanelMH314H" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/light/TouchPanelSwitch(MH 314H).png" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Touch Panel Switch MH 314H </h2>
                          <p class="product-short-description">
                              Touch Panel Switch is a wall panel built-in with Z-Wave Plus module. With its stylish design and Stable performance, the panel can be used to control house-hold electrical appliances like lamp, motor, coffee machine, TV set etc. 
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Door Window Sensor -->
<div class="modal product-modal fade" id="doorWindowSensor" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/safety/Fibaro Door Window Sensor/FGDW-002-1-Right.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Door Window Sensor</h2>
                          <p class="product-short-description">
                              FIBARO Door/Window Sensor 2 is a wireless, battery powered, Z-Wave Plus compatible magnetic contact sensor. Door/Window Sensor combines the functionality of the 2 devices: contact and temperature sensors in one easy to use product. It also has double tamper protection. 
                          </p>
                          <P class="product-short-description">
                              The FIBARO Door/Window Sensor 2 is easily mountable on doors, windows, garage doors, gates, roller blinds, closets, and more. Use it for monitoring the opening/closing of doors and windows
                              Opening is detected by separating the sensor's body and the magnet. Sensor can be used to trigger scenes and wherever there is a need for information about opening or closing of doors, windows, garage doors, etc.
                          </P>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Motion Sensor -->
<div class="modal product-modal fade" id="motionSensor" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/safety/Fibaro Motion Sensor/Motion_Sensor_Right.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Motion Sensor</h2>
                          <p class="product-short-description">
                              The Fibaro Motion Sensor is a universal Z-Wave multi-sensor. This tiny device, apart from detecting movement, measures the current ambient temperature, intensity of light and vibrations thanks to its built-in accelerometer. The FIBARO Motion Sensor expands the comfort of your reality. Create custom scenarios, so you can forget about nasty situations and enjoy the luxury of safety - its watchful eye will detect any intrusion or attempted sabotage, fire or other threats.
                          </p>
                          <P class="product-short-description">
                              To keep you and your family safe, the FIBARO Motion Sensor has built-in tamper protection. Any attempt to relocate or change the sensor's view angle will trigger the alarm and the system will inform you by sending a message to your mobile devices.
                          </P>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Smoke Sensor -->
<div class="modal product-modal fade" id="smokeSensor" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/safety/Fibaro Smoke Sensor/Smoke_Sensor_Right.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Smoke Sensor</h2>
                          <p class="product-short-description">
                              The Fibaro Motion Sensor is a universal Z-Wave multi-sensor. This tiny device, apart from detecting movement, measures the current ambient temperature, intensity of light and vibrations thanks to its built-in accelerometer. The FIBARO Motion Sensor expands the comfort of your reality. Create custom scenarios, so you can forget about nasty situations and enjoy the luxury of safety - its watchful eye will detect any intrusion or attempted sabotage, fire or other threats.
                          </p>
                          <P class="product-short-description">
                              To keep you and your family safe, the FIBARO Motion Sensor has built-in tamper protection. Any attempt to relocate or change the sensor's view angle will trigger the alarm and the system will inform you by sending a message to your mobile devices.
                          </P>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Home Center 3 -->
<div class="modal product-modal fade" id="homeCenter3" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Gateways/Fibaro Home center 3/R_packshot_HC3_3_RGB1.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Home Center 3</h2>
                          <p class="product-short-description">
                              FIBARO Home Center 3 is the hub unit of high computing power used to manage your FIBARO Home Automation System. HC2 communicates with up to 230 actors and sensors using wire- less communication protocol named Z-Wave. With FIBARO Home Center 3 your FIBARO system will be powerful. Latest creation is designed with your needs in mind. Let it change your life, making it safer and more convenient, and your home intelligent, efficient and comfortable.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Home Center Lite -->
<div class="modal product-modal fade" id="homeCenterLite" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Gateways/Fibaro Home center lite/HCL_Right1.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Home Center Lite</h2>
                          <p class="product-short-description">
                              HC Lite is a tiny device with huge potential. Incredibly quick and efficient, yet inexpensive. Control your home from anywhere in the World using a mobile phone or tablet and begin creating your own world of delights and convenience, provided by an innovative range of products.
                          </p>
                          <p class="product-short-description">
                              Introducing a new dimension of comfort Home Center Lite, the incredibly compact brain of the FIBARO System. Our latest creation is designed with your needs in mind. Let it change your life, making it safer
                              and more convenient, and your home intelligent, efficient and comfortable. Small thing – big impact!
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Roller Shutter -->
<div class="modal product-modal fade" id="rollerShutter" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Gatesnblinds/Fibaro Roller Shutter/Roller_Shutter_3_Right.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Fibaro Roller Shutter</h2>
                          <p class="product-short-description">
                              
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Shutter Panel Switch -->
<div class="modal product-modal fade" id="shutterPanelSwitch" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Gatesnblinds/Shutter panel Switch (2)/Shutter panel Switch white.png" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Shutter panel Switch</h2>
                          <p class="product-short-description">
                              
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Hanvon F910 -->
<div class="modal product-modal fade" id="hanvonF910" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/biometric/surveillance/Hanvon Face station/Hanvon F910/Hanvon F910 2.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Hanvon F910</h2>
                          <p class="product-short-description">
                              Face recognition is the ultimate solution, for meeting all the requirements. Hanvon F910 is embedded face recognition system with leading Dual Sensor Facial Recognition Algorithm, design for application like physical Access control, Time attendance and identity management. It is a stand alone, wall mounted device with Face scanner to enroll users, store their face template record, keep Attendance transaction logs and interface with computer.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Sonos One -->
<div class="modal product-modal fade" id="sonosOne" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Audio systems/Sonos one/sonos one.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Sonos One</h2>
                          <p class="product-short-description">
                              Sonos One is part of the Sonos sound system, so you can easily add more speakers to fill every room of your home with sound. The Google Assistant and Amazon Alexa are built right-in so you can play music, check news, set alarms, get your questions answered, and more, completely hands-free. Sonos One's compact and versatile design makes it great for rooms where you’re shorter on space. Pair two Sonos Ones together in the same room for stereo separation and more detailed sound.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman E300-FH -->
<div class="modal product-modal fade" id="gatemanE300FH" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman E300-FH/E300-FH.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Gateman E300-FH</h2>
                          <p class="product-short-description">
                              The stylish body with chrome plating applied to the metal body makes the  product stand out more, and the  lower part with black aluminum hairline applied to enhance the sense of unity with the keypad adds a sophisticated feel. The Safe Handle Mechanism technology is designed to open the door only by pressing a button when turning the handle, making it impossible to intrude by making a hole in the door of a household or manipulating the handle through a milk hopper.
                          </p>
                          <p class="product-short-description">
                              Have you ever worried about someone seeing your password behind you? When entering a password, you can enter an unlimited number of random numbers before or after the password, so you can use it with confidence about password exposure. Touch type products designed to open the door only by pressing a button when turning the handle may leave fingerprints after password use.  Designed to touch with the palm of your hand, the E300-FH can crush fingerprint traces by touching the keypad before and after password entry.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman G-Swipe -->
<div class="modal product-modal fade" id="gSwipe" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman G-Swipe/GatemanG-Swipe1.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Gateman G-Swipe</h2>
                          <p class="product-short-description">
                              Diamond Cutting - Work Aluminum and trim it by crystal diamond. Polished the edge exclusively. Gateman G-Swipe is the brand new flagship fingerprint  digital lock from iRevo. It has compelling improvements:  a more sensitive fingerprint scanner, a bold brushed chrome look, a more secure bolt design and innovative intrusion-prevention open button.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman J20 -->
<div class="modal product-modal fade" id="J20" tabindex="-1" role="dialog">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <i class="icofont-close"></i>
  </button>
    <div class="modal-dialog " role="document">
      <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                  <div class="col">
                      <div class="modal-image">
                          <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman J20/Gateman J20.jpg" alt="product-img" />
                      </div>
                  </div>
                  <div class="col">
                      <div class="product-short-details">
                          <h2 class="product-title">Gateman J20</h2>
                          <p class="product-short-description">
                              The Gateman J20 is a great value digital lock with rock-solid construction. Go with this model if you want to save a little more without compromising on build quality.
                          </p>
                          <p class="product-short-description">
                              With pin and card access feature, it delivers consistently, it always work. We suggest this lock strongly for all kinds of doors and use cases, including room doors, storage places, small businesses doors, and especially for tenanted premises.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
<!-- /.modal -->