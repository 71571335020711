<app-top-nav-bar></app-top-nav-bar>

<div class="card">
    <div class="card-header">
        <div class="col-lg-12 d-flex justify-content-center border-box"><h1>Products</h1></div>
        <ul class="nav nav-pills card-header-pills justify-content-center nav-justified">
            <li class="nav-item"><a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#gateways" role="tab" aria-controls="nav-home" aria-selected="true">Gateways</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#lighting" role="tab" aria-controls="nav-home" aria-selected="false">Lighting</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#cooling" role="tab" aria-controls="nav-profile" aria-selected="false">Cooling</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#safety" role="tab" aria-controls="nav-contact" aria-selected="false">Safety & Security</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#gatesnblinds" role="tab" aria-controls="nav-profile" aria-selected="false">Gates & Blinds</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#biometrics" role="tab" aria-controls="nav-contact" aria-selected="false">Biometric Sensors</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#audio" role="tab" aria-controls="nav-profile" aria-selected="false">Audio/Video</a></li>
            <li class="nav-item"><a class="nav-link" id="nav-home-tab" data-toggle="tab" href="#doorlock" role="tab" aria-controls="nav-contact" aria-selected="false">Digital Door Lock</a></li>
        </ul>
    </div>
    <div class="card-body">
        <div class="tab-content mt-3">

            <!-- Gateways -->
            <div class="tab-pane active" id="gateways" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card mb-4">
                            <img class="card-img-top img-fluid" src="assets/products/Gateways/Fibaro Home center 3/R_packshot_HC3_3_RGB1.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Home Center 3</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#homeCenter3">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Gateways/Fibaro Home center lite/HCL_Right1.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Home Center Lite </h5>
                                <p> <a href="#" data-toggle="modal" data-target="#homeCenterLite">More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lighting -->
            <div class="tab-pane fade" id="lighting" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/Fibaro Bypass.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Bypass</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fibaroBypass">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/FibaroDimmer2.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Dimmer 2</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fibaroDimmer2">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/FibaroRGBWController.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro RGBW Controller</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fibaroRGBWController">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/FibaroSmartModule.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Double Smart Module</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fibaroDoubleSmartModule">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/FibaroDoubleSmartModule.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Smart Module</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#fibaroSmartModule">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/FibaroWallPlugPackshot.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Wall Plug-Type G</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fibaroWallPlug">More</a></p>
                            </div>
                        </div>
                    </div>
                
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/FibaroSmartImplant.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Smart Implant</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#fibaroSmartImplant">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top img-fluid" src="assets/products/light/TouchPanelSwitch(MH 311H).png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Touch Panel Switch MH 311H </h5>
                                <p> <a href="#" data-toggle="modal" data-target="#touchPanelMH311H">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/TouchPanelSwitch(MH 312H).png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Touch Panel Switch MH 312H </h5>
                                <p> <a href="#" data-toggle="modal" data-target="#touchPanelMH312H">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/TouchPanelSwitch(MH 314H).png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Touch Panel Switch MH 314H </h5>
                                <p> <a href="#" data-toggle="modal" data-target="#touchPanelMH314H">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/light/Dimmer Switch MH-DT311.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Dimmer Switch MH-DT311</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#dimmerSwitchMHDT311">More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Cooling -->
            <div class="tab-pane fade" id="cooling" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/heating/Fan Coil Thermostat/Fan Coil Thermostat.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fan Coil Thermostat</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fanCoilThermostat">More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Safety & Security -->
            <div class="tab-pane fade" id="safety" role="tabpanel" aria-labelledby="nav-safety-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/safety/Fibaro CO Sensor/CO_Sensor_Right.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro CO Sensor</h5>
                                <p><a href="#" data-toggle="modal" data-target="#fibaroCoSensor">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/safety/Fibaro Door Window Sensor/FGDW-002-1-Right.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Door Window Sensor</h5>
                                <p><a href="#" data-toggle="modal" data-target="#doorWindowSensor">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/safety/Fibaro Flood Sensor/Flood_Sensor_Right.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Flood Sensor</h5>
                                <p><a href="#" data-toggle="modal" data-target="#floodSensor">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/safety/Fibaro Motion Sensor/Motion_Sensor_Right.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Motion Sensor</h5>
                                <p><a href="#" data-toggle="modal" data-target="#motionSensor">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card card-body flex-fill">
                            <img class="card-img-top" src="assets/products/safety/Fibaro Smoke Sensor/Smoke_Sensor_Right.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Smoke Sensor</h5>
                                <p><a href="#" data-toggle="modal" data-target="#smokeSensor">More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Gates & Blinds -->
            <div class="tab-pane fade" id="gatesnblinds" role="tabpanel" aria-labelledby="nav-safety-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Gatesnblinds/Fibaro Roller Shutter/Roller_Shutter_3_Right.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Fibaro Roller Shutter</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#rollerShutter">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Gatesnblinds/Shutter panel Switch (2)/Shutter panel Switch white.png" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Shutter Panel Switch</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#shutterPanelSwitch">More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Biometrics -->
            <div class="tab-pane fade" id="biometrics" role="tabpanel" aria-labelledby="nav-safety-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/biometric/surveillance/Hanvon Face station/Hanvon F910/Hanvon F910 2.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Hanvon F910</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#hanvonF910">more</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/biometric/surveillance/Hanvon Face station/Hanvon FA600/fa600 1.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Hanvon FA600</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#hanvonFA600">more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Audio -->
            <div class="tab-pane fade" id="audio" role="tabpanel" aria-labelledby="nav-safety-tab">
                <div class="row m-0">
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Audio systems/Sonos one/sonos one.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Sonos One</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#sonosOne">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Audio systems/Sonos Play 1/Sonos_PLAY.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">Sonos Play 1</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#sonosPlay1">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Audio systems/StreamAmp AM160/StreamAmp AM160.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">StreampAmp AM160</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#streampAmp">More</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 d-flex">
                        <div class="card">
                            <img class="card-img-top" src="assets/products/Audio systems/M30 Stream Pro/M30-Stream Pro.jpg" alt="product image">
                            <div class="card-body text-center">
                                <h5 class="card-title text-center">M30 Stream Pro</h5>
                                <p> <a href="#" data-toggle="modal" data-target="#m30StreamAmpPro">More</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Digital Door Lock -->
    <div class="tab-pane fade" id="doorlock" role="tabpanel" aria-labelledby="nav-safety-tab">
        <div class="row m-0">
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Gateman E300-FH/E300-FH.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Gateman E300-FH</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#gatemanE300FH">More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Gateman F300FH/F300 FH.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Gateman F300-FH</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#gatemanF300FH">More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Gateman G-Swipe/GatemanG-Swipe1.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Gateman G-Swipe</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#gSwipe">More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Gateman G-Touch/Gateman G-Touch.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Gateman G-Touch</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#gTouch">More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Gateman J20/Gateman J20.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Gateman J20</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#J20">More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Gateman Shine/GatemanShine.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Gateman Shine</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#gatemanShine">More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-3 d-flex">
                <div class="card">
                    <img class="card-img-top" src="assets/products/Digital Door Lock/Dana lock/DanaLock.jpg" alt="product image">
                    <div class="card-body text-center">
                        <h5 class="card-title text-center">Danalock V3</h5>
                        <p> <a href="#" data-toggle="modal" data-target="#danalockV3">More</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

        </div>
        
    </div>
</div>


  <!-- Modal Fibaro Bypass -->
<div class="modal product-modal fade" id="fibaroBypass" tabindex="-1" role="dialog" >
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/Fibaro Bypass.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Bypass</h2>
                            <p class="product-short-description">
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Dimmer 2 -->
<div class="modal product-modal fade" id="fibaroDimmer2" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/FibaroDimmer2.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Dimmer 2</h2>
                            <p class="product-short-description">
                                Fibaro Dimmer 2 is a revolution in lighting control. Thanks to its unique and innovative features the 
                                Dimmer 2 can recognize the connected light source and adapt itself to it. Z-wave controlled light dimming 
                                module, designed to work with various types of light sources. Fibaro Dimmer 2 can switch or dim connected 
                                light source either through Z-waves or through the wall switch connected directly to it. The power metering 
                                functionality helps to optimize your electricity bills. Using the scene activation you can customize your 
                                home even further.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->
<!-- Modal Fibaro RGBW Controller -->
<div class="modal product-modal fade" id="fibaroRGBWController" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/FibaroRGBWController.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro RGBW Controller</h2>
                            <p class="product-short-description">
                                Fibaro RGBW Controller is a one of a kind, advanced wireless 4-color LED strip controller. Apart from traditional RGB channels, it also supports the additional white light channel, which allows for adding pastel to the color scheme. In addition the device supports up to four, 0V - 10V analogue sensors, such as temperature sensors, humidity sensors, wind sensors, air quality sensors, light sensors etc. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Smart Module -->
<div class="modal product-modal fade" id="fibaroSmartModule" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/FibaroSmartModule.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Smart Module</h2>
                            <p class="product-short-description">
                                FIBARO Double Switch 2 was designed to activate and deactivate electrical devices. Thanks to its small size it can be mounted in junction boxes or inside device casings. The module is controlled either through the Z-Wave network or a simple switch. Power and energy usage measurement feature allows you to monitor and lower your utility bills. Connect your FIBARO Double Switch 2 to the system and other devices to enjoy the almost endless possibilities!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Double Smart Module -->
<div class="modal product-modal fade" id="fibaroDoubleSmartModule" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/FibaroSmartModule.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Double Smart Module</h2>
                            <p class="product-short-description">
                                The remotely operated FIBARO Single Switch 2 is designed to turn electrical devices on and off and to measure their power and energy consumption. The compact size of the device makes it the worlds smallest of its type and allows for the product to be installed in wall switch boxes or housings. Single/Double Switch 2 features many safety measures that prevent it from voltage drop, overheating, or overcharging. In the case of any of these events, the system will immediately send you a notification.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Wall Plug-Type G -->
<div class="modal product-modal fade" id="fibaroWallPlug" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/FibaroWallPlugPackshot.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Wall Plug-Type G</h2>
                            <p class="product-short-description">
                                FIBARO Wall Plug is a remotely controlled plug-in switch with the ability to measure power and energy consumption. The crystal LED ring changes its color according to the amount of power used by the plugged device. Wall Plug comes equipped with a power measurement feature. It helps you effortlessly identify the most energy-hungry pieces of hardware or monitor energy usage in particular rooms or during particular periods.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Smart Implant -->
<div class="modal product-modal fade" id="fibaroSmartImplant" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/FibaroSmartImplant.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Smart Implant</h2>
                            <p class="product-short-description">
                                The Smart Implant is the outcome of extensive studies that aimed to extend the functionality of the universal binary sensor. Through analyzing the needs of thousands of FIBARO installers and system users, we created the Smart Implant. The Smart Implant is a real game changer; it allows you to integrate your ‘non-smart’ devices with the FIBARO System and provides installers with a solution for our customers’ installation problems.
                            </p>
                            <p class="product-short-description">
                                Many “non-smart” devices can now be integrated into your FIBARO smart home system to meet your unique needs. The FIBARO Smart Implant is an efficient and cheap solution to the installation problems our customers sometimes encounter. What’s more, it is a safe and reliable solution that has no detrimental impact on existing devices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Touch Panel Switch MH 311H  -->
<div class="modal product-modal fade" id="touchPanelMH311H" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/TouchPanelSwitch(MH 311H).png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Touch Panel Switch MH 311H </h2>
                            <p class="product-short-description">
                                Touch Panel Switch is a wall panel built-in with Z-Wave Plus module. With its stylish design and Stable performance, the panel can be used to control house-hold electrical appliances like lamp, motor, coffee machine, TV set etc. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Touch Panel Switch MH 312H  -->
<div class="modal product-modal fade" id="touchPanelMH312H" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/TouchPanelSwitch(MH 312H).png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Touch Panel Switch MH 312H </h2>
                            <p class="product-short-description">
                                Touch Panel Switch is a wall panel built-in with Z-Wave Plus module. With its stylish design and Stable performance, the panel can be used to control house-hold electrical appliances like lamp, motor, coffee machine, TV set etc. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Touch Panel Switch MH 314H  -->
<div class="modal product-modal fade" id="touchPanelMH314H" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/TouchPanelSwitch(MH 314H).png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Touch Panel Switch MH 314H </h2>
                            <p class="product-short-description">
                                Touch Panel Switch is a wall panel built-in with Z-Wave Plus module. With its stylish design and Stable performance, the panel can be used to control house-hold electrical appliances like lamp, motor, coffee machine, TV set etc. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Dimmer Switch MH-DT311  -->
<div class="modal product-modal fade" id="dimmerSwitchMHDT311" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/light/Dimmer Switch MH-DT311.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Dimmer Switch MH-DT311</h2>
                            <p class="product-short-description">
                                Dimmer Switch is a wall panel built-in with Z-Wave Plus module. With its stylish design and stable performance, the panel can be used to control the light level. It supports basic command class, multi channel command class and multi channel association command class, also works as a repeater in a Z-Wave network.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro CO Sensor -->
<div class="modal product-modal fade" id="fibaroCoSensor" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/safety/Fibaro CO Sensor/CO_Sensor_Right.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro CO Sensor</h2>
                            <p class="product-short-description">
                                The FIBARO carbon monoxide sensor is a unique solution. Its high sensitivity allows to detect the presence of the carbon monoxide (CO) gas at the early stage in order to prevent carbon monoxide poisoning. The device ensures exceptional measurement precision and offers clear CO concentration graphs when connected to the system. The information concerning CO levels is saved, so you can go back through the archived data whenever you like. 
                            </p>
                            <P class="product-short-description">
                                FIBARO CO Sensor is an ultra-light, compact, battery-powered carbon monoxide detector,
                                designed to be placed on a wall. This Sensor is a life-saving device, therefore tests to conform compliance with the applicable requirements of the standard EN 50291-1:2010 are conducted by the BSI Group accredited testing laboratory.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->


<!-- Modal Fibaro Door Window Sensor -->
<div class="modal product-modal fade" id="doorWindowSensor" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/safety/Fibaro Door Window Sensor/FGDW-002-1-Right.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Door Window Sensor</h2>
                            <p class="product-short-description">
                                FIBARO Door/Window Sensor 2 is a wireless, battery powered, Z-Wave Plus compatible magnetic contact sensor. Door/Window Sensor combines the functionality of the 2 devices: contact and temperature sensors in one easy to use product. It also has double tamper protection. 
                            </p>
                            <P class="product-short-description">
                                The FIBARO Door/Window Sensor 2 is easily mountable on doors, windows, garage doors, gates, roller blinds, closets, and more. Use it for monitoring the opening/closing of doors and windows
                                Opening is detected by separating the sensor's body and the magnet. Sensor can be used to trigger scenes and wherever there is a need for information about opening or closing of doors, windows, garage doors, etc.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Flood Sensor -->
<div class="modal product-modal fade" id="floodSensor" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/safety/Fibaro Flood Sensor/Flood_Sensor_Right.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Flood Sensor</h2>
                            <p class="product-short-description">
                                Fibaro Flood Sensor is a universal, Z-Wave Plus compatible, flood and temperature sensor. Fibaro Flood Sensor is designed to be placed on the floor or on a wall with a flood sensors probe extended by connected wire. The device has built in LED and sound alarm.  
                            </p>
                            <P class="product-short-description">
                                The design of the Fibaro Flood Sensor was inspired by the perfect water drop. Every single drop, just like the modules of our system, can exist individually or they can connect with one another, forming a coherent ecosystem. The shape of a water drop is perfect and flawless, therefore our sensor’s enclosure had to look the same. The Flood Sensor is made of high quality, white, glossy plastic and is perfect in every detail. It’s design is extremely functional and the technology gives you endless possibilities.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Motion Sensor -->
<div class="modal product-modal fade" id="motionSensor" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/safety/Fibaro Motion Sensor/Motion_Sensor_Right.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Motion Sensor</h2>
                            <p class="product-short-description">
                                The Fibaro Motion Sensor is a universal Z-Wave multi-sensor. This tiny device, apart from detecting movement, measures the current ambient temperature, intensity of light and vibrations thanks to its built-in accelerometer. The FIBARO Motion Sensor expands the comfort of your reality. Create custom scenarios, so you can forget about nasty situations and enjoy the luxury of safety - its watchful eye will detect any intrusion or attempted sabotage, fire or other threats.
                            </p>
                            <P class="product-short-description">
                                To keep you and your family safe, the FIBARO Motion Sensor has built-in tamper protection. Any attempt to relocate or change the sensor's view angle will trigger the alarm and the system will inform you by sending a message to your mobile devices.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Smoke Sensor -->
<div class="modal product-modal fade" id="smokeSensor" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/safety/Fibaro Smoke Sensor/Smoke_Sensor_Right.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Smoke Sensor</h2>
                            <p class="product-short-description">
                                The Fibaro Motion Sensor is a universal Z-Wave multi-sensor. This tiny device, apart from detecting movement, measures the current ambient temperature, intensity of light and vibrations thanks to its built-in accelerometer. The FIBARO Motion Sensor expands the comfort of your reality. Create custom scenarios, so you can forget about nasty situations and enjoy the luxury of safety - its watchful eye will detect any intrusion or attempted sabotage, fire or other threats.
                            </p>
                            <P class="product-short-description">
                                To keep you and your family safe, the FIBARO Motion Sensor has built-in tamper protection. Any attempt to relocate or change the sensor's view angle will trigger the alarm and the system will inform you by sending a message to your mobile devices.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Home Center 3 -->
<div class="modal product-modal fade" id="homeCenter3" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Gateways/Fibaro Home center 3/R_packshot_HC3_3_RGB1.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Home Center 3</h2>
                            <p class="product-short-description">
                                FIBARO Home Center 3 is the hub unit of high computing power used to manage your FIBARO Home Automation System. HC2 communicates with up to 230 actors and sensors using wire- less communication protocol named Z-Wave. With FIBARO Home Center 3 your FIBARO system will be powerful. Latest creation is designed with your needs in mind. Let it change your life, making it safer and more convenient, and your home intelligent, efficient and comfortable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Home Center Lite -->
<div class="modal product-modal fade" id="homeCenterLite" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Gateways/Fibaro Home center lite/HCL_Right1.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Home Center Lite</h2>
                            <p class="product-short-description">
                                HC Lite is a tiny device with huge potential. Incredibly quick and efficient, yet inexpensive. Control your home from anywhere in the World using a mobile phone or tablet and begin creating your own world of delights and convenience, provided by an innovative range of products.
                            </p>
                            <p class="product-short-description">
                                Introducing a new dimension of comfort Home Center Lite, the incredibly compact brain of the FIBARO System. Our latest creation is designed with your needs in mind. Let it change your life, making it safer
                                and more convenient, and your home intelligent, efficient and comfortable. Small thing – big impact!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Fibaro Roller Shutter -->
<div class="modal product-modal fade" id="rollerShutter" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Gatesnblinds/Fibaro Roller Shutter/Roller_Shutter_3_Right.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fibaro Roller Shutter</h2>
                            <p class="product-short-description">
                                Fibaro Roller Shutter 3 is an advanced controller of electric roller blinds, awnings, venetian blinds and garage gates. FIBARO Roller Shutter allows precise positioning of roller blinds or venetian blind lamellas.  
                            </p>
                            <p class="product-short-description">
                                Huge versatility in choice of controlled devices, extremely small dimensions, and limit switches calibration make FIBARO Roller Shutter 3 the most advanced Z-Wave device of that type available on the market. In addition, new version of FIBARO Roller Shutter features the Power Metering functionality.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Shutter Panel Switch -->
<div class="modal product-modal fade" id="shutterPanelSwitch" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Gatesnblinds/Shutter panel Switch (2)/Shutter panel Switch white.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Shutter panel Switch</h2>
                            <p class="product-short-description">
                                Shutter Panel Switch is a wall panel built-in with Z-Wave Plus module. With its stylish design and stable performance, the panel can be used to wire roller shutter which controlled by AC motor (with limit). 
                            </p>
                            <p class="product-short-description">
                                It supports basic command class, multi channel command class and multi channel association command class, also works as a repeater in a Z-Wave network.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Hanvon F910 -->
<div class="modal product-modal fade" id="hanvonF910" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/biometric/surveillance/Hanvon Face station/Hanvon F910/Hanvon F910 2.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Hanvon F910</h2>
                            <p class="product-short-description">
                                Face recognition is the ultimate solution, for meeting all the requirements. Hanvon F910 is embedded face recognition system with leading Dual Sensor Facial Recognition Algorithm, design for application like physical Access control, Time attendance and identity management. It is a stand alone, wall mounted device with Face scanner to enroll users, store their face template record, keep Attendance transaction logs and interface with computer.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Hanvon FA600 -->
<div class="modal product-modal fade" id="hanvonFA600" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/biometric/surveillance/Hanvon Face station/Hanvon FA600/fa600 1.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Hanvon FA600</h2>
                            <p class="product-short-description">
                                If you thought that the design for Face Recognition systems couldn’t get any more sleek, you’re in for a surprise! This elite Hanvon FA600 device can work with output and input Wiegand, meaning its completely compatible with Access control systems. Now that’s a new way for Entry and Exit!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Sonos One -->
<div class="modal product-modal fade" id="sonosOne" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Audio systems/Sonos one/sonos one.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Sonos One</h2>
                            <p class="product-short-description">
                                Sonos One is part of the Sonos sound system, so you can easily add more speakers to fill every room of your home with sound. The Google Assistant and Amazon Alexa are built right-in so you can play music, check news, set alarms, get your questions answered, and more, completely hands-free. Sonos One's compact and versatile design makes it great for rooms where you’re shorter on space. Pair two Sonos Ones together in the same room for stereo separation and more detailed sound.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Play One -->
<div class="modal product-modal fade" id="sonosPlay1" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Audio systems/Sonos Play 1/Sonos_PLAY.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Sonos Play 1</h2>
                            <p class="product-short-description">
                                Sonos Play:1 is a perfect fit for bookshelves, counters, and other snug places. So now you can enjoy great-sounding music anywhere. Play different songs in different rooms or the same song everywhere. One mid-woofer ensures you’ll hear the faithful playback of mid-range vocal frequencies plus deep, rich bass. Adjustable bass and treble controls let you customize your sound by individual room or groups of rooms.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal StreampAmp AM160 -->
<div class="modal product-modal fade" id="streampAmp" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Audio systems/StreamAmp AM160/StreamAmp AM160.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">StreampAmp AM160</h2>
                            <p class="product-short-description">
                                Connects to passive speakers of proper power, iEAST Stream Amp AM160 will turn your traditional audio system into wireless, which allows you to control the wireless audio system in much more convenient way.
                            </p>
                            <p class="product-short-description">
                                Free iEAST Play applications for Android, iPhone, iPad and Mac let you comfortably control your audio system from the palm of your hand. Download the iEAST Play application of iOS version from App Store and Android version from Google Play.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal M30 Stream Pro -->
<div class="modal product-modal fade" id="m30StreamAmpPro" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Audio systems/StreamAmp AM160/StreamAmp AM160.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">M30 Stream Pro</h2>
                            <p class="product-short-description">
                                Connects to active speakers, iEAST Stream Pro will turn your traditional audio system into wireless, which allows you to control the wireless audio system in much more convenient way.
                            </p>
                            <p class="product-short-description">
                                iEAST Stream Pro does not require technical or wireless expertise. Connect the iEAST Stream Pro to active speakers in the room where you want to play music, connect to your home WiFi network with the iEAST Play application, which provides simple on-screen prompts and voice prompts. With several steps, you will be playing music in few minutes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Digital Door Locks -->
<!-- Modal Gateman E300-FH -->
<div class="modal product-modal fade" id="gatemanE300FH" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman E300-FH/E300-FH.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Gateman E300-FH</h2>
                            <p class="product-short-description">
                                The stylish body with chrome plating applied to the metal body makes the  product stand out more, and the  lower part with black aluminum hairline applied to enhance the sense of unity with the keypad adds a sophisticated feel. The Safe Handle Mechanism technology is designed to open the door only by pressing a button when turning the handle, making it impossible to intrude by making a hole in the door of a household or manipulating the handle through a milk hopper.
                            </p>
                            <p class="product-short-description">
                                Have you ever worried about someone seeing your password behind you? When entering a password, you can enter an unlimited number of random numbers before or after the password, so you can use it with confidence about password exposure. Touch type products designed to open the door only by pressing a button when turning the handle may leave fingerprints after password use.  Designed to touch with the palm of your hand, the E300-FH can crush fingerprint traces by touching the keypad before and after password entry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman F300-FH -->
<div class="modal product-modal fade" id="gatemanF300FH" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman F300FH/F300 FH.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Gateman F300-FH</h2>
                            <p class="product-short-description">
                                F300-FH lacks nothing in giving the good first impression of your house.  The door handle is positioned at the waist height which is the most natural position when the user stretches his hand. The recognition sensors of traditional fingerprint digital door locks are normally fitted on the door handle or at the lower part of the numeric pad which often fail to recognize a fingerprint.
                            </p>
                            <p class="product-short-description">
                                The gateman hook mechanism, designed with a strong interlocking structure, dramatically improves the weakness of the locking method of the latch structure, and is reinforced with a double locking method that locks once with a clasp and twice with a hook, making it more secure. Gateman's smart pad function combines digital technology and analog sensitivity in one place. Individual lighting technology designed separately one by one is applied to provide a pleasant user experience by intuitively displaying the progress of the function being used.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman G-Swipe -->
<div class="modal product-modal fade" id="gSwipe" tabindex="-1" role="dialog"> 
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman G-Swipe/GatemanG-Swipe1.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Gateman G-Swipe</h2>
                            <p class="product-short-description">
                                Diamond Cutting - Work Aluminum and trim it by crystal diamond. Polished the edge exclusively. Gateman G-Swipe is the brand new flagship fingerprint  digital lock from iRevo. It has compelling improvements:  a more sensitive fingerprint scanner, a bold brushed chrome look, a more secure bolt design and innovative intrusion-prevention open button.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman G-Touch -->
<div class="modal product-modal fade" id="gTouch" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman G-Touch/Gateman G-Touch.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Gateman G-Touch</h2>
                            <p class="product-short-description">
                                The Gateman G-Touch digital lock is the epitome of good design: simple clean lines, slim front profile, matt black brushed surface that delivers an elegant overall look.
                            </p>
                            <p class="product-short-description">
                                With pin and card access feature, it delivers consistently, it always work. We suggest this lock strongly for all kinds of doors and use cases, including room doors, storage places, small businesses doors, and especially for tenanted premises.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman J20 -->
<div class="modal product-modal fade" id="J20" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman J20/Gateman J20.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Gateman J20</h2>
                            <p class="product-short-description">
                                The Gateman J20 is a great value digital lock with rock-solid construction. Go with this model if you want to save a little more without compromising on build quality.
                            </p>
                            <p class="product-short-description">
                                With pin and card access feature, it delivers consistently, it always work. We suggest this lock strongly for all kinds of doors and use cases, including room doors, storage places, small businesses doors, and especially for tenanted premises.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Gateman Shine -->
<div class="modal product-modal fade" id="gatemanShine" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Gateman Shine/GatemanShine.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Gateman Shine</h2>
                            <p class="product-short-description">
                                The Gateman Shine digital lock for glass doors equips your home or small business with key-less electronic access without the need to drill holes on the glass doors or laying of wires. This is suitable for single and double pane glass swing doors. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Modal Danalaock V3 -->
<div class="modal product-modal fade" id="danalockV3" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/Digital Door Lock/Dana lock/Danalock V3.png" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Danalock V3</h2>
                            <p class="product-short-description">
                                The Danalock V3 is a wireless smart lock that lets you easily control access to your home with your smartphone. So stop worrying about lost or forgotten keys, use your smartphone to lock or unlock the door.
                            </p>
                            <P class="product-short-description">
                                You can create an unlimited number of digital keys for your family members, friends, guests or helpers such as a housekeeper. Program each user's access level by choosing permanent or temporary access. Set up alerts, so you know exactly when the lock is used — and by whom.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->

<!-- Fan Coil Thermostat -->
<div class="modal product-modal fade" id="fanCoilThermostat" tabindex="-1" role="dialog">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icofont-close"></i>
    </button>
      <div class="modal-dialog " role="document">
        <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <div class="modal-image">
                            <img class="img-responsive" src="assets/products/heating/Fan Coil Thermostat/Fan Coil Thermostat.jpg" alt="product-img" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="product-short-details">
                            <h2 class="product-title">Fan Coil Thermostat</h2>
                            <p class="product-short-description">
                                Fan Coil Thermostat is a Z-Wave Plus enabled device for indoor temperature control. It is mainly applied to a 2-pipe or 4-pipe Fan coil system. 
                            </p>
                            <P class="product-short-description">
                                It can read room temperature and local time, and automatically control fan speed based on the temperature difference. The device is of high reliability and practicability.
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
</div>
<!-- /.modal -->