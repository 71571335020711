<!--Navbar -->
<nav class="mb-1 navbar navbar-expand-lg navbar-dark default-color sticky-top">
  <h4><a class="navbar-brand"  href="index.html"><span>Digital Living</span></a></h4>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-333"
    aria-controls="navbarSupportedContent-333" aria-expanded="false" aria-label="Toggle navigation" style="background-color : #040f23">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent-333">
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light"  href="index.html">
          Home
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/solutions">
          Solutions
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/aboutUs">
          About Us
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/research">
          Research
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/Products">
          Products
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-effect waves-light" routerLink = "/contactUs">
          Contact Us
        </a>
      </li>
    </ul>
  </div>
</nav>
<!--/.Navbar -->