<app-top-nav-bar></app-top-nav-bar>

<div class="image-aboutus-banner">
   <div class="container">
    <div class="row">
        <div class="col-md-12">
         <h1 class="lg-text">About Us</h1>
         <p class="image-aboutus-para">Digital Living is the first Sri Lankan company which offers<br>a full solution of Intelligent smart home system.</p>
       </div>
    </div>
</div>
</div>

<div class="aboutus-secktion paddingTB60">
    <div class="container">
        <div class="row">
                	<div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
                    	<h1 class="strong">Who we are and<br>what we do</h1>
                    </div>
                    <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
                    	<p>Digital Living (Pvt) Ltd ,Launched Sri Lanka in August 2017, Digital Living focused on R&D of smart home technologies including wireless connection, smart electrical engineering, home automation, smart access control, home security, environment-dependent temperature control, artificial intelligence Etc.</p>
                        <p>Digital Living is not only the leading pioneer of smart Solutions, also all-in-one smart tech solution provider in green office system. Smart home, smart community and hotel systems. Digital Living Partners are reputed companies from Poland, Sweden & Korea.</p>
                    </div>
</div>
    </div>
</div>
<div class="container team-sektion paddingTB60 justify-content-center">
	<div class="row text-center justify-content-center">
		<div class="site-heading text-center " data-aos="fade-up" data-aos-delay="100">
			<h3>Co-founders</h3>
			<div class="border"></div>
		</div>
	</div>
	<div class="row text-center justify-content-center">
	    <div class="col-md-4 team-box" data-aos="fade-up" data-aos-delay="100">
		    <div class="team-img thumbnail">
		        <img src="assets/contact/Chethana-Photo.jpg" class=" img-fluid img-thumbnail" style="width: auto; height: 195px;">
		    	<div class="team-content">    
		        <h3>Chethana Nugawela</h3>
		        <div class="border-team"></div>
		        <p>He is the concept creator of the company. He had been engaged locally in the Telco industry and security solutions industry for many years.</p>
				<p>Having expanded his horizon for over a decade on the cutting-edge technology and after thorough research and development, he has been able to introduce a fully-fledged comprehensive intelligent home automation system for the first time to the Sri Lankan market.</p>
		        <div class="social-icons"> 
               	<a href="https://www.facebook.com/"><i id="social-fb" class="fa fa-facebook-square fa-3x social"></i></a>
               	<a href="https://twitter.com/"><i id="social-tw" class="fa fa-twitter-square fa-3x social"></i></a>
	            <a href="https://plus.google.com/"><i id="social-gp" class="fa fa-google-plus-square fa-3x social"></i></a>
	            <a href="mailto:bootsnipp@gmail.com"><i id="social-em" class="fa fa-envelope-square fa-3x social"></i></a>
	        	</div>
		    </div>
			</div>
		</div>
		<div class="col-md-4 team-box">
		    <div class="team-img thumbnail" data-aos="fade-up" data-aos-delay="100">
		        <img src="assets/contact/B.A.Svensson.jpg" class=" img-fluid img-thumbnail" style="width: auto; height: 195px;">
		    	<div class="team-content">    
		        <h3>B-A Svensson</h3>
		        <div class="border-team"></div>
				<p>He plays a significant role for the company in terms of having a strong trade co-operation with Europe & US Market for Digital automation Products.</p>
				<p>With his first carrier as a hardware technician/service engineer and After becoming Electronic engineer specialized in audio, video integration & system designing. Also he has been in Retails electronics with service & installation by managing his own Company in Sweden.</p>
		        <div class="social-icons"> 
               	<a href="https://www.facebook.com/"><i id="social-fb" class="fa fa-facebook-square fa-3x social"></i></a>
               	<a href="https://twitter.com/"><i id="social-tw" class="fa fa-twitter-square fa-3x social"></i></a>
	            <a href="https://plus.google.com/"><i id="social-gp" class="fa fa-google-plus-square fa-3x social"></i></a>
	            <a href="mailto:bootsnipp@gmail.com"><i id="social-em" class="fa fa-envelope-square fa-3x social"></i></a>
	        	</div>
		    	</div>
			</div>
		</div>
	    
	</div>
</div>

