<!-- Footer -->
<footer class=" page-footer font-small" style="background-color: #040F23;">

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5" >

    <!-- Grid row -->
    <div class="row mt-3">

       <!-- Grid column -->
       <div class="col-md-3 mx-auto">

        <!-- Content -->
         <!-- Content -->
         <h6 class="text-uppercase font-weight-bold">Digital Living</h6>
         <p>We offer solutions for Home Intelligence, Smart Workspace, Smart Access Control & Surveillence and Multi-Room Audio</p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">
        <h6 class="text-uppercase font-weight-bold">Registered Address</h6>
        <p>
          <i class="icofont-location-pin"></i> 43D, Lauries Road, Colombo 04, Sri Lanka</p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">
        <h6 class="text-uppercase font-weight-bold">Operations</h6>
        <p>
          <i class="icofont-location-pin"></i> 16/2-1 6th Lane, Pagoda Road, Nugegoda</p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 mx-auto">
        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <p>
          <i class="icofont-email"></i> info@digitalliving.lk</p>
        <p>
          <i class="icofont-phone"></i> +94 11 258 5125</p>

      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3"><h6>© 2020 Copyright: <a href="#"> DigitalLiving.lk</a></h6></div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
