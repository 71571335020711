<app-top-nav-bar></app-top-nav-bar>

<div class="image-aboutus-banner">
  <div class="container">
   <div class="row">
       <div class="col-md-12">
        <h1 class="lg-text">Contact Us</h1>
        <p class="image-aboutus-para">We'd love to hear from you!</p>
      </div>
   </div>
</div>
</div>

<div class="container team-sektion paddingTB60 justify-content-center">
	<div class="row text-center justify-content-center">
	    <div class="col-md-4 team-box">
		    <div class="team-img thumbnail">  
		    <div class="team-content">    
		        <h3>Call Us</h3>
            <div class="border-team"></div>
            <p>Sales : +94 11 258 5125</p>
            <p>Tech Support : +94 76 139 9430</p>
		    </div>
		    </div>
	    </div>
	    <div class="col-md-4 team-box">
		    <div class="team-img thumbnail">  
		    <div class="team-content">    
		        <h3>Mail Us</h3>
            <div class="border-team"></div>
            <p>Sales : info@digitalliving.lk</p>
            <p>Tech Support : support@digitalliving.lk</p>
		    </div>
		    </div>
	    </div>
</div>
</div>

<!-- ======= Contact Us Section ======= -->    
<section id="contact" class="contact">
    <div class="container">
      <div class="row ">
        <div class="col-md-6">
            <!--Google map-->
            <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 470px">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.0614190400215!2d79.89460761381599!3d6.883246295026092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25b0bc8f70e23%3A0x689757f8fc743a29!2sDigital%20Living%20Office!5e0!3m2!1sen!2slk!4v1596686200403!5m2!1sen!2slk" frameborder="0"
              style="border:0" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-md-6">
          <div class="info-box">
            <form id="contact" method="post" action="/contact" role="form">
					
              <div class="form-group">
                <input type="text" placeholder="Your Name" class="form-control" name="name" id="name" required="required">
              </div>
              
              <div class="form-group">
                <input type="tel" placeholder="Your Phone No" class="form-control" name="phone" id="phone" required="required">
              </div>
              
              <div class="form-group">
                <input type="email" placeholder="Your Email" class="form-control" name="email" id="email" required="required">
              </div>
            
              
                <div class="form-group" id="client-type" name="client-type" class="form-control" required="required">
                  <label class="radio-inline">
                    <input type="radio" name="client-type" id="end-client" value="end-client" checked>  End-client
                  </label>
                  <label class="radio-inline">
                    <input type="radio" name="client-type" id="reseller" value="reseller">  Reseller
                  </label>
                  <label class="radio-inline">
                    <input type="radio" name="client-type" id="installer" value="installer">  Installer
                  </label>
                  <div class="validate"></div>
                </div>
              
              <br>
              
              <div class="form-group">
                <textarea rows="6" placeholder="Message" class="form-control" name="message" id="message" required="required"></textarea>	
              </div>
              
              <div id="cf-submit" class="text-center justify-content-center">
                <input type="submit" class="btn btn-success btn-send" value="Send Message" style="background-color: #040F23;">
              </div>						
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

