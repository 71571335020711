<app-top-nav-bar></app-top-nav-bar>

<body data-spy="scroll" data-target=".navbar" data-offset="10">
  <header>
    <div class="overlay"></div>
    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
      <source src="assets/solution/videos/Final with crop.mp4" type="video/mp4">
    </video>
    <div class="container h-100">
      <div class="d-flex h-100 text-center align-items-center">
        <div class="cd-slider-nav w-100 text-white">
          <nav class="navbar text-center align-items-center">
            <span class="cd-marker item-1"></span>
            <div class="container-fluid" data-aos="fade-up" data-aos-delay="100">
              <ul class="nav">
                <li><a pageScroll href="#smartHome"><i class="icon icofont-home"></i><h6>Home Intelligence</h6></a></li>
                <li><a pageScroll href="#smartBuilding"><i class="icon icofont-building"></i><h6>Smart Workspace</h6></a></li>
                <li><a pageScroll href="#surveillance"><i class="icon icofont-cc-camera" ></i><h6>Smart Access Control<br>& Surveillance</h6></a></li>
                <li><a pageScroll href="#modernAudio"><i class="icon icofont-music-alt"></i><h6>Multi Room-Audio</h6></a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>

  <div class="smartHome container-fluid" id="smartHome">
    
  </div>

  <section class="container-fluid">
    <div class="heading" data-aos="fade-up" data-aos-delay="100">
      <h1>Home Intelligence</h1>
      <div class="border"></div>
    </div>
    <div class="cd-half-width second-slide">   
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="content second-content">
              <div class="row">
                <div class="col-md-7 left-image img-fluid">
                  <img src="assets/solution/img/SmartHome.jpg" >
                </div>
                <div class="col-md-5 d-flex justify-content-start">
                  <div class="text-left">
                    <p>With Smart home intelligence solutions, you can control your lighting, ventilation, air-conditioning, the households electronic appliances, safety and security with one click. This modern system utilizes sensors and switches that are connected to the gateway and controlled by a user interface on a wall-mounted terminal, mobile software, or a tablet computer.</p>
                    <p>There's no need for separate apps to control the blinds/shades, air-condition, and a light bulb. Replace them all with one that manages and controls all of these devices together. You'll be amazed when you can prepare the house on your way home by dialing back the temperature, closing the shades, and bringing up the lights without jumping app-to-app.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>                  
      </div>
    </div>
  </section>
  
  <div class="smartBuilding container-fluid" id="smartBuilding">
    
  </div>
  <section class="container-fluid" data-aos="fade-up" data-aos-delay="100">
      <div class="heading">
        <h1>Smart Workspace</h1>
        <div class="border"></div>
      </div>
        <div class="cd-half-width second-slide">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="content second-content">
                  <div class="row">
                    <div class="col-md-5 d-flex justify-content-start">
                      <div class="text-left">
                        <p>We are working together to create workplaces where anything is possible. Through intelligent and automatic lighting, air-conditioning, heating and movement detection, we make greater energy efficiency and increased security easier than ever. With digital solutions everything can even be controlled remotely, giving you complete control wherever you are, whenever you need it most.</p>
                        <p>Every Workplace has unique requirements therefore; our innovative portfolio provides the flexibility in designing and implementing building solutions that adapt easily to individual needs. Moreover, your investment is secured for future modifications and extensions, especially when the building is to be used for a different purpose.</p>
                      </div>
                    </div>
                    <div class="col-md-7 right-image img-fluid">
                      <img src="assets/solution/img/Smart Office.jpg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>                 
        </div>
  </section>
  
  <div class="surveillance container-fluid" id="surveillance">
    
  </div>
  <section class="container-fluid" data-aos="fade-up" data-aos-delay="100">
    <div class="heading">
      <h1>Smart Access Control & Surveillance</h1>
      <div class="border"></div>
    </div>
    <div class="cd-half-width second-slide">   
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="content second-content">
              <div class="row">
                <div class="col-md-7 left-image img-fluid">
                  <img src="assets/solution/img/SurveillanceSystem.jpg">
                </div>
                <div class="col-md-5 d-flex justify-content-start">
                  <div class="text-left">
                    <p>The smart access control solutions are using advanced technologies to further enhance the accuracy and security of the access rights verification process. These technologies include face recognition, fingerprint recognition, tailgating detection and others.</p>
                    <p>Video door intercoms are an important element to consider in the connected home. With a video door intercom, homeowners can monitor package deliveries and surprise visits from friends and relatives.</p>
                    <p>Our intelligent video surveillance solution offers comprehensive IP video surveillance software products which are capable of addressing small, medium and large installations. With the surveillance system, you can check the security level of your house members or working place staff. With our surveillance solutions, you can also use for identifying security threats with a built-in camera. Hardware used in surveillance solutions must be safe, reliable, secure, and run 24/7.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>                  
      </div>
    </div>
  </section>
  
  <div class="modernAudio container-fluid" id="modernAudio">
    
  </div>
  <section class="container-fluid" data-aos="fade-up" data-aos-delay="100">
    <div class="heading">
      <h1>Multi-Room Audio</h1>
      <div class="border"></div> 
    </div>
    <div class="cd-half-width third-slide">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="content second-content">
              <div class="row">
                <div class="col-md-5 d-flex justify-content-start">
                  <div class="text-left">
                    <p>Elevate your listening experience with a high-performance audio system that will wow even the most discerning audiophiles. We bring the highest quality audio equipment to suit your entertainment needs.  As part of simplifying your digital lifestyle, media servers stream music anywhere in your home from the internet. From a single room to an entire home or outdoor. Our home media system can be controlled through a remote, Smartphone, tablet, or even your voice!</p>
                    <p>Audio distribution systems send music to any room in your home via a central processor or matrix switcher. Whether the speakers are placed in the walls, ceiling, bookshelves, shower or pool, they can be designed to aesthetically blend in to minimize visual impact. In fact, don't worry about wires, we have wireless solutions that enable you to enjoy your music from anywhere.</p>
                  </div>
                </div>
                <div class="col-md-7 right-image img-fluid">
                  <img src="assets/solution/img/MultiroomAudio.jpg" style="width: 100%; height: 400px;">
                </div>
              </div>
            </div>
          </div>
        </div>                  
      </div>
    </div>
  </section>
</body>  